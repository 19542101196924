<template>
  <!-- 培训记录详情 -->
  <div class="trainingDetails">
    <van-nav-bar :title="$t('trainRecordsDetails.TrdTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="comeBack"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem;"></div>
    <div class="middle">
      <van-cell class="first_cell"
                :title="obj.coursename"
                :label="$t('trainRecordsDetails.TrdTxt2')+obj.empname"
                :value="obj.trainscore" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt3')"
                :value="obj.listno" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt4')"
                :value="obj.yymmdd" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt5')"
                :value="obj.empcode" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt6')"
                :value="obj.empname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt7')"
                :value="obj.deptname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt8')"
                :value="obj.staname" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt9')"
                :value="obj.traintype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt10')"
                :value="obj.trainstyle" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt11')"
                :value="obj.plantype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt12')"
                :value="obj.trainlevel" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt13')"
                :value="obj.signtype" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt14')"
                :value="obj.signbt" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt15')"
                :value="obj.signet" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt16')"
                :value="obj.signres" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt17')"
                :value="obj.testres" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt18')"
                :value="obj.testbt" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt19')"
                :value="obj.testet" />
      <van-cell class="last_cell"
                :title="$t('trainRecordsDetails.TrdTxt20')"
                :value="obj.planbt+'-'+obj.planet" />
    </div>
    <van-divider />
    <div class="AttFileList_box"
         style="padding: 0 15px;">
      <div style="font-size: 16px; font-weight: bold;margin-bottom: 15px;">附件</div>
      <ul v-if="fileList.length>0"
          style="display: flex;flex-wrap: wrap;justify-content: space-between;">
        <li style="text-align: center; padding: 0px 20px 10px 20px;"
            v-for="(item,index) in fileList"
            :key="index"
            @click="showFile(item)">
          <img v-if="item.type == 'doc'"
               src="@/assets/img/word.png"
               alt />
          <img v-else-if="item.type == 'xls'"
               src="@/assets/img/excel2.png"
               alt />
          <img v-else-if="item.type == 'ppt'"
               src="@/assets/img/ppt2.png"
               alt />
          <img v-else-if="item.type == 'pic'"
               src="@/assets/img/picture.png"
               alt />
          <img v-else-if="item.type == 'zip'"
               src="@/assets/img/zip.png"
               alt />
          <img v-else-if="item.type == 'default' || 'pdf'"
               src="@/assets/img/xmind.png"
               alt />
          <div style="font-size: 14px;">{{item.sfilename}}</div>
        </li>
      </ul>
      <ul v-else>
        <li style="font-size: 16px;text-align: center;margin-bottom: 15px;">{{$t('confirmInterviewInfo.confirmInfoTxt31')}}</li>
      </ul>
    </div>

    <!-- pdf内置播放器 -->
    <pdfView :pdf="pdf"></pdfView>
  </div>
</template>

<script>
import pdfView from '@components/pdfView/index.vue'
import { ImagePreview, Notify } from 'vant'
import { getTrainRecordInfo } from "@api/wxpx.js";
import { getModuleAttFileList, checkFileExists, getDownLoadFileName } from '@api/wxzp.js'
export default {
  components: {
    pdfView
  },
  data () {
    const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
    return {
      userInfo,
      result: "",
      obj: {},
      fileList: [], // 附件列表
      attachfilepath: 'AttachFile',
      pdf: '',
    };
  },
  created () {
    getTrainRecordInfo({
      autoid: this.$route.query.autoid,
    }).then((res) => {
      console.log(res);
      this.obj = res.data[0];
    });
    this.getModuleAttFileListData()
  },
  methods: {
    comeBack () {
      this.$router.push({ path: "/trainRecords" });
    },
    // 获取附件列表
    getModuleAttFileListData () {
      getModuleAttFileList({
        moduleno: 488,
        mautoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        this.fileList = this.filehandleData(res)
        console.log(this.fileList, 'this.fileList');

      })
    },
    // 附件数据处理
    filehandleData (list) {
      let groups = [] // 分组[后缀]
      let res = [] // 第一次分类的集合
      if (list.module_atlist)
      {
        list.module_atlist.forEach(item => {
          let type = ''
          let groupName = !item.aftype ? '' : item.aftype
          const ext = item.fileext.slice(1) // 后缀
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      if (list.other_atlist)
      {
        list.other_atlist.forEach(item => {
          let type = ''
          const ext = item.fileext.slice(1) // 后缀
          let groupName = !item.aftype ? '' : item.aftype
          let url = ''
          if ('doc|docx'.includes(ext))
          {
            name = 'WORD文件'
            type = 'doc'
            url = require('@assets/img/word.png')
          } else if ('xlsx|xls'.includes(ext))
          {
            name = 'EXCEL文件'
            type = 'xls'
            url = require('@assets/img/excel2.png')
          } else if ('ppt|pptx'.includes(ext))
          {
            name = 'PPT文件'
            type = 'ppt'
            url = require('@assets/img/ppt2.png')
          } else if ('pdf'.includes(ext))
          {
            name = 'PDF文件'
            type = 'pdf'
            url = require('@assets/img/picture.png')
          } else if ('png|jpg|jpeg|emp'.includes(ext))
          {
            name = '图片文件'
            type = 'pic'
            url = require('@assets/img/picture.png')
          } else if ('zip|rar'.includes(ext))
          {
            name = '压缩文件'
            type = 'zip'
            url = require('@assets/img/zip.png')
          } else
          {
            name = '其他文件'
            type = 'default'
            url = require('@assets/img/xmind.png')
          }
          item.type = type
          // item.url = url
          item.isImage = true
          res.push(item)
        })
      }
      return res
    },
    // 点击附件
    showFile (item) {
      if (item.type === 'pic' || 'pdf')
      {
        const params = {
          moduleno: item.moduleno,
          dflag: 2,
          autoid: item.mautoid,
          filename: item.sfilename.split('.')[0], // filename不要带后缀
          fileext: item.fileext,
          downloadpath: this.attachfilepath
        }
        checkFileExists(params).then(re => {
          if (re == 'OK')
          {
            getDownLoadFileName(params).then(e => {
              // this.fileList[index].url = e
              if (item.type === 'pic')
              {
                ImagePreview([e])
              } else if (item.type === 'pdf')
              {
                this.pdf = e + '?time=' + new Date().getTime()
              } else
              {
                var u = navigator.userAgent
                var isAndroid =
                  u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
                if (isAndroid)
                {
                  //android终端
                  window.open(e, '_blank')
                } else if (isiOS)
                {
                  //ios终端
                  window.location.href = e
                } else
                {
                  window.open(e, '_blank')
                }
              }
            })
          } else
          {
            Notify({ type: 'warning', message: this.$t('module.nofile') })
          }
        })
      }
    }
  },
};
</script>

<style lang="less" scoped>
.trainingDetails {
  .pdfView {
    height: 0;
  }
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  // background: #eceaea;
  background: #ffffff;
  .van-icon {
    position: absolute;
    top: 50px;
    left: 30px;
    font-size: 38px;
  }
  .headerImg {
    height: 472px;
  }
  .middle {
    .first_cell {
      .van-cell__title {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        line-height: 68px;
        color: #333333;
      }
      .van-cell__label {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #666666;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #999999;
      }
    }
    .first_cell::after {
      border-bottom: unset;
    }
    .last_cell {
      .van-cell__title {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 54px;
        color: #333333;
      }
      .van-cell__value {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 48px;
        color: #666666;
      }
    }
  }
  .van-divider {
    padding: 0 30px;
  }
  .AttFileList_box {
    ul {
      li {
        img {
          height: 104px;
        }
      }
    }
  }
  .footer {
    .title {
      text-align: center;
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 62px;
      color: #333333;
    }
    .content {
      padding: 0 30px;
      text-indent: 2em;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 54px;
      color: #333333;
    }
    .result {
      text-align: center;
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 68px;
      color: #666666;
    }
  }
}
</style>